import * as THREE from 'three';

import {
    RGBELoader
} from 'three/examples/jsm/loaders/RGBELoader.js';
import {
    OrbitControls
} from 'three/examples/jsm/controls/OrbitControls.js';

import {
    GUI
} from 'lil-gui/dist/lil-gui.esm.min.js';

import {
    gsap
} from "gsap";

// Extern als http://localhost/wrk/mirror2/dist/

// ============== Hier die Hintergruende eintragen: --START-- ===============
const hdrTexArr = [
    new URL('../../static/quarry_01_1k.hdr',
        import.meta.url),
    new URL('../../static/moonless_golf_1k.hdr',
        import.meta.url),
    new URL('../../static/pedestrian_overpass_1k.hdr',
        import.meta.url),
    new URL('../../static/venice_sunset_1k.hdr',
        import.meta.url),
];

// Hier die Hintergruende eintragen: --ENDE--

// ================ Hier die Bilder eintragen: --START-- =================
const imgArr = [
    new URL('../../img/ute.jpg',
        import.meta.url),
    new URL('../../img/kirsch.jpg',
        import.meta.url),
    new URL('../../img/blu.jpg',
        import.meta.url),
    new URL('../../img/bier2.jpg',
        import.meta.url),
    new URL('../../img/bobo.jpg',
        import.meta.url),
    new URL('../../img/roka3.jpg',
        import.meta.url),
    new URL('../../img/bier1.jpg',
        import.meta.url)
];
// Hier die Bilder eintragen: --ENDE--


let camera, scene, renderer;
let cubea, cubeb, sphere, torusa, torusb, materialSphere;
let materialTora, materialTorb;

const opt = {
    boxScale: 1,
    boxRad: 1,
    backIdx: 0,
    imgIdx: 0,
    show: true,
}

let cubeCamera, cubeRenderTarget;
let controls;

function loadBackTexture(nURL) {
    new RGBELoader().load(nURL, function (bkTexture) {
        bkTexture.mapping = THREE.EquirectangularReflectionMapping;
        scene.background = bkTexture;
        scene.environment = bkTexture;
    });
}

function init() {

    renderer = new THREE.WebGLRenderer({
        antialias: true
    });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setAnimationLoop(animation);
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    document.body.appendChild(renderer.domElement);

    window.addEventListener('resize', onWindowResized);

    camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 1, 1000);
    camera.position.z = 75;

    scene = new THREE.Scene();
    scene.rotation.y = 0.5; // avoid flying objects occluding the sun

    loadBackTexture(hdrTexArr[0])

    //

    cubeRenderTarget = new THREE.WebGLCubeRenderTarget(256);
    cubeRenderTarget.texture.type = THREE.HalfFloatType;

    cubeCamera = new THREE.CubeCamera(1, 1000, cubeRenderTarget);

    // Kugel
    materialSphere = new THREE.MeshStandardMaterial({
        envMap: cubeRenderTarget.texture,
        roughness: 0.05,
        metalness: 1
    });

    const gui = new GUI();
    gui.add(materialSphere, 'roughness', 0, 1);
    gui.add(materialSphere, 'metalness', 0, 1);
    gui.add(renderer, 'toneMappingExposure', 0, 2).name('exposure');
    gui.add(opt, 'boxScale', 0, 2);
    gui.add(opt, 'boxRad', 0, 2);
    gui.add(opt, 'show');
    gui.add(opt, 'backIdx',  hdrTexArr.map((e,idx)=>idx)   )
        .onChange(e =>      loadBackTexture(hdrTexArr[e])
        )
    gui.add(opt, 'imgIdx', imgArr.map((e,idx)=>idx) )
        .onChange(e => {
            cubea.visible = false
            cubeb.visible = false
            cubea = cubeaArr[e];
            cubeb = cubebArr[e];
            cubea.visible = true
            cubeb.visible = true
        })

    sphere = new THREE.Mesh(new THREE.IcosahedronGeometry(15, 8), materialSphere);
    scene.add(sphere);

    materialTora = new THREE.MeshStandardMaterial({
        roughness: 0.2,
        metalness: 0.2,
        color: 0xFF0000,
    });
    materialTorb = new THREE.MeshStandardMaterial({
        roughness: 0.2,
        metalness: 0.2,
        color: 0x0000FF,
    });

    torusa = new THREE.Mesh(new THREE.TorusKnotGeometry(7, 3, 128, 16), materialTora);
    scene.add(torusa);
    torusb = new THREE.Mesh(new THREE.TorusGeometry(7, 3, 16, 48), materialTorb);
    scene.add(torusb);

    controls = new OrbitControls(camera, renderer.domElement);
    controls.autoRotate = true;
    controls.autoRotateSpeed = 0.4;
}

function onWindowResized() {
    renderer.setSize(window.innerWidth, window.innerHeight);
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
}

// ====== MAIN =============
init();

// Array mit Cubes
var cubeaArr = [];
var cubebArr = [];
imgArr.forEach(e => {
    const cmat = new THREE.MeshStandardMaterial({
        roughness: 0.2,
        metalness: 0.2,
        map: new THREE.TextureLoader().load(e)
    });
    const bx = 17
    const ca = new THREE.Mesh(new THREE.BoxGeometry(bx, bx, bx), cmat)
    const cb = new THREE.Mesh(new THREE.BoxGeometry(bx, bx, bx), cmat)
    //ca.visible=false;
    //cb.visible=false;
    cubeaArr.push(ca)
    cubebArr.push(cb)
    const off = cubeaArr.length * 30
    ca.position.set(off, 0, 0)
    cb.position.set(-off, 0, 0)
    scene.add(ca)
    scene.add(cb)
})

cubea = cubeaArr[0];
cubeb = cubebArr[0];
cubea.visible = true;
cubeb.visible = true;

const torCols = [ // Colors der Toroiden
    new THREE.Color(0xFF0000),
    new THREE.Color(0x00FF00),
    new THREE.Color(0x0000FF),
    new THREE.Color(0xFFFF00),
    new THREE.Color(0xFF00FF),
    new THREE.Color(0x00FFFF),
    new THREE.Color(0xFFFFFF)
]

let imgIdx = 1;
let bkIdx = 1;
let cidx = 0;

setInterval(() => {
    if (!opt.show) return;
    console.log("Img: ", imgIdx, " Bk: ", bkIdx)

    let cidx2;
    do {
        cidx2 = Math.floor(Math.random() * torCols.length)
    } while (cidx2 == cidx) // NTSC
    const or = opt.boxRad
    const os = opt.boxScale
    const c0 = torCols[cidx];
    const c1 = torCols[cidx2];
    if (cidx++ >= torCols.length) cidx = 0;

    function wechsel() {
        materialTora.color = c0;
        materialTorb.color = c1;
        cubea.visible = false;
        cubeb.visible = false;
        cubea = cubeaArr[imgIdx];
        cubeb = cubebArr[imgIdx];
        cubea.visible = true;
        cubeb.visible = true;

        imgIdx++;
        if (imgIdx >= imgArr.length) {
            loadBackTexture(hdrTexArr[bkIdx])
            imgIdx = 0
            if (++bkIdx >= hdrTexArr.length) bkIdx = 0
        }
    }
    var t1 = gsap.timeline({
        repeat: 0
    });
    t1.to(opt, {
        boxRad: 0.1,
        boxScale: 0.1,
        duration: 2,
        ease: "back.in"
    })
    t1.call(wechsel)
    t1.to(opt, {
        boxRad: or,
        boxScale: os,
        duration: 2,
        ease: "back.out"
    })
}, 5000)

function animation(msTime) {

    const time = msTime / 2000;

    cubea.scale.set(opt.boxScale, opt.boxScale, opt.boxScale)
    cubeb.scale.set(opt.boxScale, opt.boxScale, opt.boxScale)

    const rad = opt.boxRad * 30;
    const s0 = Math.sin(time) * rad
    const c0 = Math.cos(time) * rad
    cubea.position.x = c0
    cubea.position.y = s0
    cubea.position.z = s0
    cubeb.position.x = -c0
    cubeb.position.y = -s0
    cubeb.position.z = -s0


    cubeb.rotation.x += 0.012; // Invers
    cubeb.rotation.y += 0.014;
    cubea.rotation.x -= 0.012;
    cubea.rotation.y -= 0.014;
    torusa.position.x = s0
    torusa.position.y = c0
    torusa.position.z = -c0
    torusb.position.x = -s0
    torusb.position.y = -c0
    torusb.position.z = c0

    torusb.rotation.x += 0.015;
    torusb.rotation.y += 0.02;
    torusa.rotation.x += 0.02;
    torusa.rotation.y += 0.03;

    cubeCamera.update(renderer, scene);
    controls.update();

    renderer.render(scene, camera);

}